import React from 'react';
import SpanWithTooltip from 'components/SpanWithTooltip';
import ModalLinks from './ModalLinks';
import { ReactComponent as IcoDescargar} from 'icons/descargar.svg';

const mapColumnas = {
    Fecha: 'fecha_realizacion',
    Estudio: 'estudio',
    Servicio: 'servicio',
    Estado: 'estado'
}

const FilaEstudios = ({col, estudio}) => {
    const icoStyle = {width: '20px', height: '20px'};
    switch(col){
        case 'Ver Informe':    
        return (
            (estudio.path_documento && estudio.path_documento.trim() !== '') ? (
                <a href={estudio.path_documento} download>
                    <IcoDescargar style={icoStyle}/>
                </a>
            ) : (
                <a href={estudio.url_informe} download>
                    <IcoDescargar style={icoStyle}/>
                </a>
            )
        );
        case 'Ver Imagen':
            return (
                <ModalLinks
                    estudio={estudio}
                />
            )
        default:
            return <SpanWithTooltip text={estudio[mapColumnas[col]]} />
    }
};

export default FilaEstudios;
